import Advice from "./Advise";


const Footer = () => {


return (

    
    <footer className="relative bg-blueGray-200 pt-8 pb-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-6/12 px-4">
            <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
            </h5>
            <div className="mt-6 lg:mb-0 mb-6 ">


            </div>
          </div>

        </div>
        <hr className="my-6 border-blueGray-300"/>
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full px-4 mx-auto text-center">
            <div className="text-xs">
          <Advice/>
          </div>
            <div className="text-sm text-blueGray-500 py-1">
              Copyright © <span id="get-current-year">2023</span>
              <div className="text-blueGray-500 hover:text-blueGray-300 text-xs"> Mick Ivory and his Rangers from Hell</div>
            </div>
          </div>
        </div>
      </div>
    </footer>

)

}

export default Footer;