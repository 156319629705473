const Contact = () => {
    return (
        <div className="contact mt-8 ">
            <h1 className="text-2xl font-bold text-center ">Contact</h1>
            <div className="text-lg mt-5 ">
            <p>Email: <a className="underline" href="mailto:steven.athanas@gmail.com">steven.athanas@gmail.com</a></p>
            <p>Facebook: <a className="underline" href="https://www.facebook.com/steven.j.athanas">https://www.facebook.com/steven.j.athanas</a></p>
            </div>

        </div>
    )
}

export default Contact;