import ModalImage from "react-modal-image";


const Landing = () => {
    const imageurl = "/landing.jpg";
    const steve = "/landing_steve.jpg"
    return (
        <div className="landing mt-8 grid justify-items-center">
            <div className="max-w-3xl bg-white border border-gray-200 rounded-lg shadow bg-transparent  dark:border-gray-700">
                <ModalImage small={imageurl} large={imageurl} alt={"Welcome"} /> 
                <div className="text-lg text-center font-semibold text-gray-500">
                3 Sisters Eating Ice Cream in the Long Grass on a Windy Day
                </div>
                <div className="mt-2 text-sm mb-4 text-center text-gray-400">
                Watercolor, Micron pens, pencil. Approx 16”X10” Framed
                </div>
            </div>


            <div className="landing-text text-base mt-4 text-justify max-w-3xl">
<p>Hello and welcome to “All Things Ath,” where I’m inviting you to explore a little bit of my world and its creations. 
    Whether it’s visual or musical, I’ve been creating for over 6 decades, and I have no interest in stopping. Thanks 
    for taking the time to stop by.
</p>

<p className="mt-3">
In the <span className="italic hover:not-italic">Gallery</span> you will find pieces I have made, some as far back as 1998. Watercolor, sculptures, my written 
works – it’s all represented. In the <span className="italic hover:not-italic">Shop</span> portion of the website, you’ll find pieces that are for sale. These 2 portals are not mutually 
exclusive, however. 
If there’s something in the <span className="italic hover:not-italic">Gallery</span> that you would like to have, please drop me an email and let’s discuss it.    
</p>

<p className="mt-3">
You’ll notice that I have a very keen interest in the preservation and conservation of our most precious asset: water. No one on this planet is 
exempt from the necessity of this commodity. From a small leaky faucet in a public bathroom to the negligent dumping of tons of waste into our waterways, 
we must become aware of this dilemma, and do what we can – what we must – to stop our wrongdoing. 
Our very lives and the lives of every living creature depend on it. It’s that simple.
</p>

<p className="mt-3">
Again, I welcome you to my site and look forward to any critique/commentary/what-have-you that you may have.
</p>

<div className="grid justify-items-center mt-12">
<div className="max-w-xl bg-white border border-gray-200 rounded-lg shadow bg-transparent  dark:border-gray-700 ">
                <ModalImage small={steve} large={steve} alt={"Steven Jay"} /> 
                <div className="text-lg text-center mt-1 mb-1 bg-transparent">
                Steven Jay Athanas
                </div>
            </div>
            </div>


            </div>
        </div>
    )
}

export default Landing;