import React, { useEffect, useState } from "react";
// import styled from "styled-components";
import { createClient } from "@supabase/supabase-js"

const Media = () => {
    const [articleData, setArticleData] = useState([]);


    useEffect(() => {

        const getTable = async() => {

            const supabase =  createClient(
                'https://begsaeljcuzpgjlpujob.supabase.co','eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJlZ3NhZWxqY3V6cGdqbHB1am9iIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY5NTc0NTI3OCwiZXhwIjoyMDExMzIxMjc4fQ.hZ_XqJLfBMDTn0HrvC5gMaNomGNDf7PcyLx5UyPHvx4'
                // process.env.NEXT_PUBLIC_SUPABASE_URL || '',
                // process.env.SUPABASE_SERVICE_ROLE_KEY || ''
              )
              const { data: articles } = await supabase
                .from('articles')
                .select('*')
                setArticleData(articles);  
        };

        getTable();
    }, []);

    return (
        <div className="media">
            <div className="text-base mt-4 text-justify">
                <h1 className="text-2xl font-bold text-center">Articles</h1>

                If you look up the phrase “big fish in a small pond” there’s sure to be a picture of me somewhere. 
                Mr. Warhol, are my 15 minutes up? Here are some 
                kind words that have been spoken about me and my meanderings over the expanse of Time
            </div>
            <div className="grid md:grid-cols-2 gap-3 mt-8 justify-items-center ">
                <img src="/steven-jay-wedding-pic_cropped-808x1024.jpg" alt="SJA" className=" border-white border-2 rounded-lg mt-8" width="80%"  />
                <div className="mt-4">
                    <div className="text-gray-900 text-base mt-5">   
                        <ul className="list-disc">
                        {articleData.map((article) => (
                            <li key={article.ID} className="mt-3">
                                <a href={article.LINK} target="_blank" rel="noreferrer" className="underline">{article.TITLE}</a>
                            </li>
                        ))}
                        </ul>                    

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Media;