import {createBrowserRouter} from "react-router-dom";
// import Footer from './components/Footer';
import Gallery from './components/Gallery';
import Landing from './components/Landing';
// import PageHeader from './components/PageHeader';
import Bio from "./components/Bio";
import Water from "./components/Water";
import Contact from "./components/Contact";
import Media from "./components/Media";

import './App.css';

const  router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />
  },
  {
    path: "/gallery",
    element: <Gallery />
  },
  {
    path: "/bio",
    element: <Bio />
  },
  {
    path: "/water",
    element: <Water />
  },
  {
    path: "/contact",
    element:  <Contact />
  },
  {
    path: "/media",
    element: <Media />
  }
]);

export default router;

