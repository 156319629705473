import { useState } from 'react'
import { React } from "react";
// import ModalImage from "react-modal-image";
// import Lightbox from "react-modal-image";


// function useOnClickOutside(ref, handler) {
//     useEffect(
//         () => {
//             const listener = (event) => {
//                 // Do nothing if clicking ref's element or descendent elements
//                 if (!ref.current || ref.current.contains(event.target)) {
//                     return;
//                 }
//                 handler(event);
//             };
//             document.addEventListener("mousedown", listener);
//             document.addEventListener("touchstart", listener);
//             return () => {
//                 document.removeEventListener("mousedown", listener);
//                 document.removeEventListener("touchstart", listener);
//             };
//         },
//         // Add ref and handler to effect dependencies
//         // It's worth noting that because the passed-in handler is a new ...
//         // ... function on every render that will cause this effect ...
//         // ... callback/cleanup to run every render. It's not a big deal ...
//         // ... but to optimize you can wrap handler in useCallback before ...
//         // ... passing it into this hook.
//         [ref, handler]
//     );
// }





const ArtItem = (props) => {
    // const [showModal, setShowModal] = useState(false);
    const imagebase = "https://begsaeljcuzpgjlpujob.supabase.co/storage/v1/object/public/art/";
    const imageurl = imagebase + props.data.ID + ".jpg"
    const youtube =
        <div class="aspect-video w-full">

            <iframe src={props.data.YTURL} frameborder="0" width={600} height={400} title={props.data.ID} allow="accelerometer; 
        clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        // console.log("CLOSING")
        setShowModal(false);
    };

    const sendMail = (id = undefined, title = undefined) => {
        // console.log("sendMail", id, title, id !== undefined)
        if (id !== undefined) {
            window.open('mailto:steven.athanas@gmail.com?subject=' + title + ' (' + id + ')');
        }
    };
    const handleOpenModal = () => {
        // console.log("OPENING")
        setShowModal(true);
    };


    const Xodal = (props) => {
        // console.log(props.data)
        return (
            <div onClick={handleCloseModal}>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-50 outline-none focus:outline-none">
                    <div className="absolute top-5 w-auto  mx-auto max-w-sm md:max-w-2xl my-auto " style={{ maxHeight: "20%" }}>
                        {/*content*/}
                        <div className="border-1 max-h-min rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-xl font-semibold">
                                    {props.data.TITLE}
                                </h3>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setShowModal(false)}>
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                            </div>
                            {/*body*/}
                            <div className="relative pl-4 pr-4 flex-auto">
                                {props.data.YTURL ? youtube :
                                    <img src={imageurl} className="" alt="" />}
                                <div >
                                    <p className="my-1 text-slate-500 text-sm leading-relaxed">
                                        {props.data.DESCRIPTION.replaceAll("?", '"')}
                                    </p>
                                    <p className=" text-slate-500 text-lg leading-relaxed">
                                        Price ${props.data.PRICE} {props.data.SOLD > 0 ? <span className="font-bold">: SOLD</span> : ""}
                                        <span className="ml-96 text-slate-300 text-xs  leading-relaxed">
                                            {props.data.ID}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-red-500 bg-emerald-500background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => handleCloseModal()}
                                >
                                    Close
                                </button>
                                <button
                                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => {sendMail(props.data.ID, props.data.TITLE); handleCloseModal()}}>
                                    I want it. Contact Steven Jay...
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </div>)
    }






    return (

        <div className="max-w-sm bg-white border-gray-200 rounded-lg shadow dark:bg-transparent dark:border-white-700 border-0 relative" >
            {showModal ? Xodal(props) : <></>}
            {/* {showModal ? <h1>On</h1>:<h1>Off</h1>} */}
            <div className="border-white border-2" onClick={handleOpenModal}>
                {/* <ModalImage small={imageurl} large={imageurl} alt={props.data.TITLE} /> */}
                <img src={imageurl} alt={props.data.TITLE} className="rounded-t-lg" />

            </div>


            <div className="p-5 ">

                <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-600 dark:text-gray">{props.data.TITLE}</h5>

                <p className="mb-9 font-normal text-gray-700 dark:text-gray-900 text-sm">{props.data.DESCRIPTION}</p>
                {/* <div className=" absolute bottom-2 right-2  px-3 py-2 text-sm font-medium text-center text-white bg-slate-600 rounded-lg 
                                    hover:bg-black focus:ring-4 
                                    focus:outline-none focus:ring-blue-300  "
                                    onClick={handleOpenModal}> */}
                <div className="absolute bottom-2 right-2  px-3 py-2 text-sm font-medium 
                                    text-center text-slate-600 text underline hover:text-black 
                                    border-2 rounded-lg border-gray-300"
                    onClick={handleOpenModal}>
                    More Information...
                    {/* <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg> */}
                </div>
            </div>
        </div>

    )
}

//     <div className="max-w-sm bg-white border-gray-200 rounded-lg shadow dark:bg-transparent dark:border-white-700 border-0 ">
//     <div className="border-white border-2">
//     <ModalImage small={imageurl} large={imageurl} alt={props.data.TITLE} />
//     </div>


// <div className="p-5">

//         <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-600 dark:text-gray">{props.data.TITLE}</h5>

//     <p className="mb-3 font-normal text-gray-700 dark:text-gray-900 text-sm">{props.data.DESCRIPTION}</p>
//     <div  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 
//                         focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
//         Read more
//         <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
//             <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
//         </svg>
//     </div>
// </div>
// </div>

// const MModal = ({onClose})=>{
//     const ref = useRef()
//     useEffect(() => {
//       const checkIfClickedOutside = e => {
//         if (ref.current && !ref.current.contains(e.target)) {
//           onClose()
//         }
//       }
//       document.addEventListener("click", checkIfClickedOutside)
//       return () => {
//         document.removeEventListener("click", checkIfClickedOutside)
//       }
//     }, [onClose])
// }

export default ArtItem;