
const Bio = () => {
    return (

        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-3 mt-8 justify-items-center ">
            <img src="/sja.jpg" alt="SJA" className=" border-white border-2 rounded-lg mt-8" width="80%"  />
            <div className="mt-4">
                <h1 className="text-2xl font-bold">Steven Jay Athanas</h1>
                <div className="text-gray-600 text-base mt-5">   
                    

<p className="mt-3 text-justify">
Athanas has been making art professionally for over 6 decades. He has self-published several books of his drawings and “pomes” (his term). His work 
has shown in Detroit, Cleveland, Ann Arbor, Blissfield, West Palm Beach, Colorado, Washington D.C., New York City and Toledo. He received his BA in 
ArtEd from the University of Toledo. He has also released multiple recordings of his original songs.
</p>        

<p className="mt-3 text-justify">
Athanas works in both 2D and 3D. His 3D work utilizes reimagined and found items, such as rusty wire and objects from Nature, as well as tissue 
paper, clay and whatever else he can get his hands on. He has long been making mobiles and other sculptures using these materials, attaching them 
to wire armatures to make fish, birds, human, and abstract forms. 
They are usually painted on with acrylic paints and coated with non-flammable polyurethane for strength
</p>

<p className="mt-3 text-justify">
His 2D works are fantastical, whimsical works, more often than not with the aforementioned “pomes” and accompanying watercolor paintings.    
</p>

<p className="mt-3 text-justify">
He is currently working on a book of these 2D pieces through University Press, scheduled to be released in the Spring of 2024.    
</p>


                </div>
            </div>
        
        
        </div>
    )
}

export default Bio;