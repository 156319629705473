const Water = () => {


    return (
        <div className="grid  mx-auto md:grid-cols-2 gap-10 place-content-center">
            <div className="mt-4">
                <div className="text-gray-900 text-base mt-5 text-center">
                    <div className="text-2xl font-bold ">The Water T Project</div>
                    <div className="text-sm font-italic">Thousands have lived without Love, not one without water.”  — W.H. Auden</div>
                </div>
                <div className="text-gray-700 text-base text-justify mx-auto">
                    <p className="mt-4">
                        Clearly humans, aquatic mammals, and fish need clean water to survive. To bring awareness to the impact that water pollution is having,
                        I’m collaborating with an old friend, Jim Arvanitis, Founder/President of Sea King CBD, to create some thought-provoking drawings like
                        the ones below and bring awareness to the importance of water preservation and conservation. I’m also working on a few more
                        inspirational drawings that I hope will be available soon. We’re calling this particular project "The Water T Project".
                    </p>
                    <p className="mt-4">
                        Each drawing will be printed on high-quality, 100% cotton using non-toxic inks. You can order your Water T’s on my website (see below
                        or the “Shop” page). Just click on the one you like. You can also order Water Ts on Arvanitis’ website www.seakingcbd.com.
                        While you’re there, checkout the outstanding CBD brands and products they have at guaranteed low prices.
                    </p><p className="mt-4">
                        We will be donating 75% of our net profit from the sale of Water Ts to non-profits who are involved in ocean cleanup activities. Arvanitis
                        is speaking with several of them who are interested in collaborating with us. More details to follow.
                    </p>
                </div>



            </div>
            <div className="flex flex-col place-content-center text-center mx-auto justify-items-center mt-6">
                <div className="">
                    <a href="https://allthingsath.com/water-is-magic/">
                    <div className="align-items-center">
                        <img src="/iceland-g63256ab43_1920-1024x680.jpg" alt="Iceland" className="mx-auto border-2 border-white" width="80%" height="150" />
                    </div>
                    <div className="text-base underline ">Water Is Magic</div>
                    </a>
                </div>
                <div className="mt-10">
                    <a href="https://allthingsath.com/our-oceans-need-help/">
                    <div className="align-items-center">
                        <img src="/cycle-of-microplastics.jpg" alt="Cycle" className="mx-auto border-2 border-white" width="80%" height="150" />
                    </div>
                    <div className="text-base  underline">Our Oceans Need Help</div>
                    </a>
                </div>
            </div>
        </div>

    )
}

export default Water;