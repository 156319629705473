// const Menu = () => {

// return (

    
// <nav className="bg-white border-gray-200 dark:bg-gray-900 text-base mt-6 w-full ">
//   {/* <div className=" flex flex-wrap items-center justify-stretch mx-auto p-2"> */}
//   <div className=" justify-items-center items-center justify-stretch mx-auto p-2">


//     <div className=" w-full md:w-auto" id="navbar-default">
//       <ul className="font-medium flex flex-col justify-center p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
//         <li>
//           <a href="/" className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">Home</a>
//         </li>
//         <li>
//           <a href="/bio" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Bio</a>
//         </li>
//         <li>
//           <a href="/gallery" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Gallery</a>
//         </li>
//         <li>
//           <a href="/media" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Media</a>
//         </li>
//         <li>
//           <a href="/water" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">All About Water</a>
//         </li>
//         <li>
//           <a href="/contact" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
//         </li>
//       </ul>
//     </div>
//   </div>
// </nav>


// )

// }

// export default Menu;



import React, { useState } from 'react';

function Menu() {
 const [isOpen, setIsOpen] = useState(false);

 return (
   <nav className="flex items-center justify-between flex-wrap mt-3 w-full ">
     {/* <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-72"> */}
       {/* <img src={locofy} className="w-100 h-10 mr-2" alt="Logo" /> */}
     {/* </div> */}
     <div className="block lg:hidden ">
       <button
         onClick={() => setIsOpen(!isOpen)}
         className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400">
         <svg
           className={`fill-current h-3 w-3 ${isOpen ? "hidden" : "block"}`}
           viewBox="0 0 20 20"
           xmlns="http://www.w3.org/2000/svg"
         >
           <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
         </svg>
         <svg
           className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
           viewBox="0 0 20 20"
           xmlns="http://www.w3.org/2000/svg"
         >
           <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
         </svg>
       </button>
     </div>
     <div
       className={`w-full block flex-grow lg:flex lg:justify-items-center lg:items-center bg-gray-700  lg:w-auto ${isOpen ? "block" : "hidden"}`}
     >
       <div className="text-base  lg:flex-grow  text-center mt-3">
        <a href="/" className="block mt-4 lg:inline-block lg:mt-0 text-white mr-4 pl-3" aria-current="page">Home</a>

        <a href="/bio" className="block mt-4 lg:inline-block lg:mt-0 text-white 1 pl-3 pr-8">Bio</a>
        <a href="/gallery" className="block mt-4 lg:inline-block lg:mt-0 text-white pl-3 pr-5">Gallery</a>
        <a href="/media" className="block mt-4 lg:inline-block lg:mt-0 text-white pl-3 pr-5 ">Media</a>
        <a href="/water" className="block mt-4 lg:inline-block lg:mt-0 text-white pl-3 pr-5 ">All About Water</a>
        <a href="/contact" className="block mt-4 lg:inline-block lg:mt-0 text-white pl-3 pr-5  mb-3">Contact</a>
         
       </div>

     </div>
   </nav>
 );
}
export default Menu;