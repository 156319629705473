import Menu from "./Menu";



function PageHeader() {    
    return (   
        <div className="grid justify-items-center bg-white  top-0 w-full">
            <div className="mt-4 text-5xl  text-gray-900">All Things Ath</div>
            <div className="mt-2 text-sm  text-gray-900 text-center">The Art, Thoughts, and Meanderings of Steven Jay Athanas</div>     
<Menu/> 
        </div>
    )
}
export default PageHeader;