import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import router from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from "react-router-dom";
import PageHeader from './components/PageHeader';
import Footer from './components/Footer';
// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <div></div>,
//   },
// ]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="mx-auto">
      <PageHeader />

      <header className="container mx-auto ">
        <RouterProvider router={router} />
        <Footer/> 
      </header>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
