import React, { useEffect, useState } from "react";
// import styled from "styled-components";
import { createClient } from "@supabase/supabase-js"
import ArtItem from "./ArtItem";
import {Tabs, TabList, Tab, TabPanel}   from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const GalleryText = 
<div className="landing-text text-base mt-4 text-justify mb-5">
    <p className="mt-3">
    Hey, welcome to the Ath "Gallery" page. This page reflects works I have made over the decades. Some are available for sale, but 
    the best bet is to go to the “Shop” portion of the site. Having said that, I am always open to custom making a piece from this page, if you’d like.     
    </p>
    <p className="mt-3">
    Again: any questions/comments/critiques are welcome. Feel free to <a href="/contact" >contact me</a>.
    </p>
    <p className="mt-3">
    FYI: A word about the “Fig.” works. If you recall, a lot of your school books (Science, Math, et al) would have photos/diagrams that would 
    state “Fig. 7D: The Elements Chart” for example. They were put there to help one understand the text. Those always stayed with me and seeped 
    their way into my cranium, then my art work, and became a whole facet of my ongoing work.
    </p>
    <p className="mt-3">
        Enjoy!
    </p>
</div>


const Gallery = (props) => {
    const [tabledata, setTabledata] = useState([])
    const [tabs, setTabs] = useState([])
    // const [isModalOpen, setIsModalOpen] = useState(false)


    useEffect(() => {

        const getTable = async() => {

            const supabase =  createClient(
                'https://begsaeljcuzpgjlpujob.supabase.co','eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJlZ3NhZWxqY3V6cGdqbHB1am9iIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY5NTc0NTI3OCwiZXhwIjoyMDExMzIxMjc4fQ.hZ_XqJLfBMDTn0HrvC5gMaNomGNDf7PcyLx5UyPHvx4'
                // process.env.NEXT_PUBLIC_SUPABASE_URL || '',
                // process.env.SUPABASE_SERVICE_ROLE_KEY || ''
              )
              const { data: catalog } = await supabase
                .from('catalog')
                .select('*')
                .order('ID')
                setTabledata(catalog);  
            // unique tabs
            var xtabs = {}
             catalog.forEach((row)=>{
                xtabs[row.SECTION]=1;
             })      
             setTabs(Object.keys(xtabs))

        };

        getTable();
    }, []);

    var tgroups = {};

    tabs.forEach((tab)=>{
        var filtered = tabledata.filter((x)=>x.SECTION===tab)    
        tgroups[tab]=filtered
    })
    

    return (
        <div className="container">
            {GalleryText}
        <Tabs>
            <TabList>
                {tabs.map((tab,idx) =>
                    <Tab key={idx}><p className="text-lg"> {tab}</p></Tab>
                )}
            </TabList>

                {tabs.map((tab,tidx) =>
                    <TabPanel key={tidx}>
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                            {tgroups[tab].map((item,idx) => (
                                <ArtItem key={idx+tidx*1000} data={item}/>            
                            ))}
                        </div>
                    </TabPanel>
                )}
        </Tabs>
        </div>
    );
};

export default Gallery;

// const Wrapper = styled.div`
//     padding-top: 150px;
//     margin: 0 auto;
// `;

// const Paragraph = styled.h2`
   
//     font-weight: bold;
//     font-size: 20px;
//     line-height: 48px;
//     text-align: center;
// `;